
import { computed, defineComponent, ref, watch } from 'vue'
import InputCheckbox from './InputCheckbox.vue'
import cms from '@/cms'
import { IFormInputCheckbox, ITheme } from '@/types'
import { useStore } from '@/composables/use-store'

export default defineComponent({
  components: {
    InputCheckbox
  },

  props: {
    isErrorShown: {
      type: Boolean,
      required: true
    }
  },

  emits: ['input'],

  setup (_, { emit }) {
    const store = useStore()

    const theme = computed<ITheme>(() => store.getters.theme)

    const enabledNewsletters = cms.campaign.newsletters.filter(newsletter => newsletter.enabled)
    const consentNewsletters = enabledNewsletters.filter(newsletter => newsletter.signup_mode !== 'implicit')
    const _consentMap: Record<number, boolean> = {}
    for (const newsletter of consentNewsletters) {
      _consentMap[newsletter.id] = false
    }

    const values = ref<{accepted: boolean, id: number, required: boolean, valid: boolean}[]>(
      consentNewsletters.map(newsletter => ({
        accepted: false,
        id: newsletter.id,
        required: newsletter.signup_mode === 'required',
        valid: newsletter.signup_mode === 'optional'
      }))
    )

    const updateValue = (index: number, value: boolean) => {
      const obj = values.value[index]
      obj.accepted = value
      obj.valid = !obj.required || value
      emit('input', values.value)
    }

    watch(values, () => {
      emit('input', values.value)
    }, { deep: true, immediate: true })

    const checkboxDefs: IFormInputCheckbox[] = consentNewsletters.map(newsletter => {
      const messages: Record<string, any> = {}
      for (const localeCode of Object.keys(newsletter.locales)) {
        const locale = newsletter.locales[localeCode]
        messages[localeCode] = {
          label: locale.consent_label || `I would like to subscribe to the ${newsletter.name} newsletter`,
          overline: '',
          error: locale.consent_error || 'Please accept the newsletter subscription'
        }
      }
      return {
        type: 'input-checkbox',
        data: {
          id: newsletter.id.toString(),
          messages,
          required: newsletter.signup_mode === 'required',
          // TODO: Some refactoring/removal of theme injection would be handy
          rounded: theme.value.rounded,
          background: theme.value.inputColor,
          checkedColor: theme.value.primaryColor,
          textColor: theme.value.textInput,
        }
      }
    })

    return {
      consentNewsletters,
      values,
      checkboxDefs,
      theme,
      updateValue
    }
  }
})
