import Vuex, {
  MutationTree,
  GetterTree,
  ActionTree,
  Module,
  ActionContext,
} from 'vuex'
import Vue from 'vue'
import {
  IRestForms,
  ISectionFormWithoutThemeAndSectionData,
} from '@/types'
import { RootState } from '..'
import { sleep } from '@/utils/sleep'
import cms from '../../cms'
import forms from '@/rest/forms'

Vue.use(Vuex)

export interface FormsState {
  newsletter: ISectionFormWithoutThemeAndSectionData | null;
  competition: ISectionFormWithoutThemeAndSectionData | null;
  isNewsletterSubmitted: boolean
  isCompetitionSubmitted: boolean
}

export const formsState: FormsState = {
  newsletter: null,
  competition: null,
  isNewsletterSubmitted: false,
  isCompetitionSubmitted: false,
}

const formsMutations: MutationTree<FormsState> = {
  SET_FORM_NEWSLETTER (state: FormsState, form: ISectionFormWithoutThemeAndSectionData) {
    state.newsletter = form
  },
  SET_FORM_COMPETITION (state: FormsState, form: ISectionFormWithoutThemeAndSectionData) {
    state.competition = form
  },
  SET_FORM_IS_COMPETITION_SUBMITTED (state: FormsState, isCompetitionSubmitted: boolean) {
    state.isCompetitionSubmitted = isCompetitionSubmitted
  },
  SET_FORM_IS_NEWSLETTER_SUBMITTED (state: FormsState, isNewsletterSubmitted: boolean) {
    state.isNewsletterSubmitted = isNewsletterSubmitted
  },
}

const formsActions: ActionTree<FormsState, RootState> = {
  async SUBMIT_NEWSLETTER_FORM (ctx: ActionContext<FormsState, RootState>, data: { email: string, country: string, name: string, consents: {accepted: boolean, id: number}[] }) {
    if (!cms.newsletter.enabled) {
      // Mark submitted anyway because combined form with cms newsletter disabled causes problems
      ctx.commit('SET_FORM_IS_NEWSLETTER_SUBMITTED', true)
    }
    try {
      await cms.newsletter.signup({
        email: data.email,
        country: data.country,
        name: data.name,
        consents: data.consents,
      })
      cms.tracking.trackCustomer('Newsletter', 'Single Opt-In - Successful', 'newsletter_soi_success', {
        newsletter_selection: cms.newsletter.addressbookId,
      })
      ctx.commit('SET_FORM_IS_NEWSLETTER_SUBMITTED', true)
    } catch (e) {
      cms.tracking.trackCustomer('Newsletter', 'Single Opt-In - Fail', 'newsletter_soi_fail', {
        newsletter_selection: cms.newsletter.addressbookId,
        error_message: (e as string),
      })
      throw (e)
    }
  },
  async SUBMIT_COMPETITION_FORM (ctx: ActionContext<FormsState, RootState>, data: { fields: Record<string, string | boolean> }) {
    const locale = cms.i18n.currentLocale.code
    await forms.signup(ctx.rootState.campaignId, { fields: data.fields, locale })
    ctx.commit('SET_FORM_IS_COMPETITION_SUBMITTED', true)
  },
}

const formsGetters: GetterTree<FormsState, RootState> = {
  formNewsletter: (state: FormsState) => state.newsletter,
  formCompetition: (state: FormsState) => state.competition,
  formIsCompetitionSubmitted: (state: FormsState) => state.isCompetitionSubmitted,
  formIsNewsletterSubmitted: (state: FormsState) => state.isNewsletterSubmitted,
  formAllFormsAreSubmitted: (state: FormsState) => {
    const combined = state.newsletter?.data.formType === 'combined' || state.competition?.data.formType === 'combined'
    if (combined) {
      return state.isNewsletterSubmitted && state.isCompetitionSubmitted
    }
    const forms = [[state.newsletter, state.isNewsletterSubmitted], [state.competition, state.isCompetitionSubmitted]]
    for (const [form, submitted] of forms) {
      if (!form) {
        continue
      }
      if (!submitted) {
        return false
      }
    }
    return true
  }
}

export const formsModule: Module<FormsState, RootState> = {
  state: formsState,
  mutations: formsMutations,
  getters: formsGetters,
  actions: formsActions,
}
