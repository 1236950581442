import Vuex, { MutationTree, GetterTree, ActionTree, Module } from 'vuex'
import Vue from 'vue'
import { RootState } from '..'
import { ITheme, IThemeFont } from '@/types'

Vue.use(Vuex)

export type ThemeState = ITheme

export const themeState: ThemeState = {
  isDark: false,
  primaryColor: '#747AB4',
  secondaryColor: '#EFDEE4',
  backgroundColor: '#EBCDD6',
  textInput: '#000',
  inputColor: '#D498AB',
  text: '#000',
  buttonText: '#fff',
  footerIconColor: '#747AB4',
  font: IThemeFont.POPPINS,
  rounded: 'medium',
}

const themeMutations: MutationTree<ThemeState> = {
  SET_THEME (state: ThemeState, themeState: ITheme) {
    Object.assign(state, themeState)
  },
  SET_THEME_IS_DARK (state: ThemeState, isDark) {
    state.isDark = isDark
  },
  SET_THEME_PRIMARY_COLOR (state: ThemeState, primaryColor) {
    state.primaryColor = primaryColor
  },
  SET_THEME_SECONDARY_COLOR (state: ThemeState, secondaryColor) {
    state.secondaryColor = secondaryColor
  },
  SET_THEME_BACKGROUND_COLOR (state: ThemeState, backgroundColor) {
    state.backgroundColor = backgroundColor
  },
  SET_THEME_INPUT_COLOR (state: ThemeState, inputColor) {
    state.inputColor = inputColor
  },
  SET_THEME_TEXT_INPUT_COLOR (state: ThemeState, textInput) {
    state.textInput = textInput
  },
  SET_THEME_TEXT_COLOR (state: ThemeState, text) {
    state.text = text
  },
  SET_THEME_BUTTON_TEXT_COLOR (state: ThemeState, buttonText) {
    state.buttonText = buttonText
  },
  SET_THEME_FOOTER_ICON_COLOR (state: ThemeState, footerIconColor) {
    state.footerIconColor = footerIconColor
  },
  SET_THEME_FONT (state: ThemeState, font) {
    state.font = font
  },
  SET_THEME_ROUNDED (state: ThemeState, rounded) {
    state.rounded = rounded
  },
}

const themeActions: ActionTree<ThemeState, RootState> = {
}

const themeGetters: GetterTree<ThemeState, RootState> = {
  theme: (state: ThemeState) => state,
  themeIsDark: (state: ThemeState) => state.isDark,
  themePrimaryColor: (state: ThemeState) => state.primaryColor,
  themeSecondaryColor: (state: ThemeState) => state.secondaryColor,
  themeInputColor: (state: ThemeState) => state.inputColor,
  themeBackgroundColor: (state: ThemeState) => state.backgroundColor,
  themeText: (state: ThemeState) => state.text,
  themeTextInput: (state: ThemeState) => state.textInput,
  themeFont: (state: ThemeState) => state.font,
  themeButtonText: (state: ThemeState) => state.buttonText,
  themeRounded: (state: ThemeState) => state.rounded,
  themeFooterIconColor: (state: ThemeState) => state.footerIconColor,
}

export const themeModule: Module<ThemeState, RootState> = {
  state: themeState,
  mutations: themeMutations,
  getters: themeGetters,
  actions: themeActions,
}
